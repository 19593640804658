export const oesteVindiAtualizacaoCliente = {
  title: "Vindi - Atualização Cliente",
  tabs: {
    operation: {
      label: "Resumo de Operações",
      title: "Resumo de Operações",
      description: "Últimas operações realizadas no sistema.",
      cleanOption: true,
      downloadOption: true,
      columns: [
        { label: "Atualizações Totais", column: "renovacoes_totais" },
        { label: "Sucesso", column: "sucesso" },
        { label: "Erro", column: "error" },
        {
          label: "Início Atualização",
          column: "inicio_renovacao",
          format: "date"
        },
        { label: "Fim Atualização", column: "fim_renovacao", format: "date" },
        { label: "Status", column: "status" }
      ],
      actions: {
        run: {
          active: false,
          label: "Executar"
        }
      }
    },
    feature1: {
      active: true,
      label: "Atualização",
      title: "Inserir atualizações",
      cleanOption: true,
      downloadOption: true,
      description:
        "Insira dados na tabela via arquivo CSV ou adição unitária de renovação.",
      columns: [
        { label: "ID Cliente", column: "idCliente", align: "left" },
        { label: "Nome", column: "clientName", align: "left" },
        { label: "Email", column: "clientEmail", align: "left" },
        { label: "Documento", column: "clientDoc" },
        { label: "Telefones", column: "clientPhone" },
        { label: "Validação Arquivo", column: "validationStatus" },
        { label: "Observação", column: "validationObs" },
        {
          label: "Adicionado em",
          column: "createdAt",
          format: "date"
        },
        {
          label: "Resposta Vindi",
          column: "statusCodeApi"
        }
      ],
      actions: {
        run: {
          active: true,
          label: "Executar"
        },
        unitaryAction: {
          active: false,
          label: "Atualização unitária"
        },
        uploader: {
          active: true,
          label: "Upload arquivos CSV"
        }
      }
    }
  }
};
