export const oesteVindiBaixaManual = {
  title: "Vindi - Baixa Manual Fatura",
  tabs: {
    operation: {
      label: "Histórico Atualizações",
      title: "Histórico Atualizações",
      description: "Últimas atualizações efetuadas.",
      cleanOption: true,
      downloadOption: true,
      columns: [
        { label: "Atualizações Totais", column: "renovacoes_totais" },
        { label: "Sucesso", column: "sucesso" },
        { label: "Erro", column: "error" },
        {
          label: "Início Atualização",
          column: "inicio_renovacao",
          format: "date"
        },
        { label: "Fim Atualização", column: "fim_renovacao", format: "date" },
        { label: "Status", column: "status" }
      ],
      actions: {
        run: {
          active: false,
          label: "Executar"
        }
      }
    },
    feature1: {
      active: true,
      cleanOption: true,
      downloadOption: true,
      label: "Últimos registros",
      title: "Últimos registros",
      description: "Confira as últimas atualizações dos registros.",
      columns: [
        { label: "Nome", column: "nome", align: "left" },
        { label: "Email", column: "email", align: "left" },
        { label: "Documento", column: "clientDocumento" },
        { label: "Fatura", column: "fatura" },
        { label: "Status", column: "status" },
        {
          label: "Adicionado em",
          column: "createdAt",
          format: "date"
        },
        {
          label: "Resposta Vindi",
          column: "resposta_pagar",
          renderCell: "status"
        }
      ],
      actions: {
        run: {
          active: true,
          label: "Executar"
        },
        unitaryAction: {
          active: false,
          label: "Renovação unitária"
        },
        uploader: {
          active: true,
          label: "Upload arquivos CSV"
        }
      },
      modalUnitary: {
        title: "Renovação Unitária",
        fields: [
          {
            name: "assinatura",
            type: "input",
            label: "Assinatura",
            placeholder: "Assinatura",
            mask: "number"
          },
          {
            name: "novaAssinatura",
            type: "input",
            label: "Nova assinatura",
            type: "checkbox"
          },
          {}
        ]
      }
    }
  }
};
