export const cspescaConciliacaoTabelasPreco = {
    title: "Omie - Conciliação Tabelas de Preço",
    tabs: {
      operation: {
        label: "Resumo de Operações",
        title: "Resumo de Operações",
        description: "Últimas operações realizadas no sistema.",
        cleanOption: true,
        downloadOption: true,
        columns: [
          { label: "Atualizações Totais", column: "renovacoes_totais" },
          { label: "Sucesso", column: "sucesso" },
          { label: "Erro", column: "error" },
          { label: "Tipo de Atualização", column: "actionFlow" },
          {
            label: "Início Atualização",
            column: "inicio_renovacao",
            format: "date"
          },
          { label: "Fim Atualização", column: "fim_renovacao", format: "date" },
          { label: "Status", column: "status" }
        ],
        actions:{
            run: {
                active: true,
                label: "Executar"
            },
        }
      },
      feature1: {
        active: true,
        label: "Conciliação",
        title: "Resumo Conciliações",
        cleanOption: true,
        downloadOption: true,
        description:
          "Detalhes da execução do fluxo de conciliação.",
        columns: [
          { label: "Código Tabela", column: "priceTableCode", align: "left" },
          { label: "Nome Tabela", column: "priceTableName", align: "left" },
          { label: "Código Produto", column: "codigoProduto", align: "left" },
          { label: "Valor Produto", column: "valorProduto", align: "left" },
          { label: "Filial", column: "filial" },
          { label: "Situação", column: "situacao" },
          { label: "Descr. Falha", column: "descricaoErro" },
          { label: "Flow", column: "flowId"}, 
          {
            label: "Adicionado em",
            column: "createdAt",
            format: "date"
          },
          {
            label: "Resposta Omie",
            column: "resposta_pagar",
            renderCell: "status"
          }
        ],
        actions: {
          run: {
            active: true,
            label: "Executar"
          },
          unitaryAction: {
            active: false,
            label: "Atualização unitária"
          },
          uploader: {
            active: true,
            label: "Upload arquivos CSV"
          }
        }
      }
    }
  }
  