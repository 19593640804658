export const cieloConciliacaoRecebimentos = {
    title: "Omie - Conciliação Recebimentos",
    tabs: {
      operation: {
        label: "Resumo de Operações",
        title: "Resumo de Operações",
        description: "Últimas operações realizadas no sistema.",
        cleanOption: true,
        downloadOption: true,
        columns: [
          { label: "Atualizações Totais", column: "renovacoes_totais" },
          { label: "Sucesso", column: "sucesso" },
          { label: "Erro", column: "error" },
          { label: "Tipo de Atualização", column: "actionFlow" },
          {
            label: "Início Atualização",
            column: "inicio_renovacao",
            format: "date"
          },
          { label: "Fim Atualização", column: "fim_renovacao", format: "date" },
          { label: "Status", column: "status" }
        ],
        actions:{
            run: {
                active: true,
                label: "Executar"
            },
        }
      },
      feature1: {
        active: true,
        label: "Conciliação",
        title: "Resumo Conciliações",
        cleanOption: true,
        downloadOption: true,
        description:
          "Detalhes da execução do fluxo de conciliação.",
        columns: [
          { label: "Pedido Omie", column: "pedidoErp", align: "center" },
          { label: "Pedido App", column: "pedidoApp", align: "center" },
          { label: "Cliente", column: "cliente", align: "center" },
          { label: "Valor", column: "valor", align: "center", format: "currency" },
          { label: "Valor Liquido", column: "valorLiquido", align: "center", format: "currency" },
          { label: "Tipo Pagamento", column: "tipoPagamento", align: "center" },
          { label: "Bandeira", column: "bandeira", align: "center" },
          { label: "Nº Parcelas", column: "numParcelas", align: "center" },
          { label: "Taxa Cobrada", column: "taxaCobrada", align: "center" },
          { label: "Taxa Configurada", column: "taxaConfigurada", align: "center" },
          { label: "Taxa Antecipação", column: "taxaAntecipacao", align: "center" },
          { label: "Data Inclusão", column: "dataInclusao", align: "center" },
          { label: "Data Pagamento", column: "dataPagamento", align: "center" },
          { label: "Situação", column: "situacao", align: "center" },          
          { label: "Flow", column: "flowId", align: "center"}, 
          {
            label: "Adicionado em",
            column: "createdAt",
            format: "date"
          },
          {
            label: "Resposta Omie",
            column: "resposta_pagar",
            renderCell: "status"
          }
        ],
        actions: {
          run: {
            active: true,
            label: "Executar"
          },
          unitaryAction: {
            active: false,
            label: "Atualização unitária"
          },
          uploader: {
            active: true,
            label: "Upload arquivos CSV"
          }
        }
      }
    }
  }
  