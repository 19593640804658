export const elevWhatsApp = {
  title: "Central de Atendimento WhatsApp",
  tabs: {
    operation: {
      label: "Chat",
      title: "Chat",
      description: "Últimas operações realizadas no sistema.",
      columns: [
        { label: "Cancelamentos Totais", column: "renovacoes_totais" },
        { label: "Sucesso", column: "sucesso" },
        { label: "Erro", column: "error" },
        {
          label: "Início Cancelamento",
          column: "inicio_renovacao",
          format: "date"
        },
        { label: "Fim Cancelamento", column: "fim_renovacao", format: "date" },
        { label: "Status", column: "status", format: "status" }
      ],
      actions: {
        run: {
          active: false,
          label: "Executar"
        }
      }
    },
    feature1: {
      active: true,
      label: "Cancelamentos",
      title: "Inserir cancelamento",
      description:
        "Insira dados na tabela via arquivo CSV ou adição unitária de cancelamento.",
      columns: [
        { label: "Nome", column: "nome", align: "left" },
        { label: "Email", column: "email", align: "left" },
        { label: "Assinatura Atual", column: "assinatura" },
        { label: "Status", column: "status" },
        {
          label: "Resposta Pagar.me",
          column: "resposta_pagar",
          renderCell: "status"
        }
      ],
      actions: {
        run: {
          active: true,
          label: "Executar"
        },
        unitaryAction: {
          active: true,
          label: "Cancelamento unitária"
        },
        uploader: {
          active: true,
          label: "Upload arquivos CSV"
        }
      }
    }
  }
};
