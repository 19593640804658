import { registerApplication, start } from "single-spa";
import { showWhenPrefix } from "./RoutingUtils";
import { cancellationOeste } from "./rpaConfigs/uploaders/cancellation-oeste";
import { wnfChat } from "./rpaConfigs/chat/wnf";
import { elevWhatsApp } from "./rpaConfigs/chat/elev-whatsapp";
import { renewOeste } from "./rpaConfigs/uploaders/renew-oeste";
import { renewVindiReport } from "./rpaConfigs/uploaders/renew-vindi-report";
import { oesteVindiReativacao } from "./rpaConfigs/uploaders/oeste-vindi-reativacao";
import { oesteVindiCancelamento } from "./rpaConfigs/uploaders/oeste-vindi-cancelamento";
import { renewVindiOeste } from "./rpaConfigs/uploaders/renew-vindi-oeste";
import { rpaCadastro } from "./rpaConfigs/schedulers/rpa-cadastro";
import { rpaConciliacao } from "./rpaConfigs/schedulers/rpa-conciliacao";
import { oesteVindiAtualizacao } from "./rpaConfigs/uploaders/oeste-vindi-atualizacao";
import { oesteVindiFaturas } from "./rpaConfigs/uploaders/oeste-vindi-faturas";
import { oestePagarmeReport } from "./rpaConfigs/uploaders/oeste-pagarme-report";
import { oesteVindiNovaAssinatura } from "./rpaConfigs/uploaders/oeste-vindi-nova-assinatura";
import { oesteLinkPagamento } from "./rpaConfigs/uploaders/oeste-link-pagamento";
import { oesteVindiExtract } from "./rpaConfigs/uploaders/oeste-vindi-extract";
import { oesteCarrinhoAbandonado } from "./rpaConfigs/uploaders/oeste-carrinho-abandonado";
import { oesteAcompanhamentoLives } from "./rpaConfigs/uploaders/oeste-lives";
import { oesteVindiAtualizacaoFatura } from "./rpaConfigs/uploaders/oeste-vindi-atualizacao-fatura";
import { oesteConciliacao } from "./rpaConfigs/uploaders/oeste-conciliacao";
import { oesteVindiAtualizacaoCliente } from "./rpaConfigs/uploaders/oeste-vindi-atualizacao-cliente";
import { oesteVindiBaixaManual } from "./rpaConfigs/uploaders/oeste-vindi-baixa-manual";
import { oesteVindiGcp } from "./rpaConfigs/uploaders/oeste-vindi-gcp";
import { cspescaConciliacaoTabelasPreco } from "./rpaConfigs/uploaders/cspesca-conciliacao-tabelas-preco";
import { cieloConciliacaoRecebimentos } from "./rpaConfigs/uploaders/cielo-conciliacao-recebimentos";

registerApplication({
  name: "@intraversa/portico-react",
  app: () => System.import("@intraversa/portico-react"),
  activeWhen: () =>
    showWhenPrefix(["/home", "/forgotPassword", "/profile", "/user-profile"]) ||
    location.pathname === "/"
});

registerApplication({
  name: "@intraversa/portico-scheduled",
  app: () => System.import("@intraversa/portico-scheduled"),
  activeWhen: () => {
    return showWhenPrefix([
      "viewb-",
      "/viewb-register",
      "/viewb-conciliation",
      "/viewb-cancellation",
      "/inadimplencia",
      "/elev-bitrix-omie",
      "/bamko-any-omie"
    ]);
  },
  customProps: () => {
    const configs = {
      "viewb-register": rpaCadastro,
      "viewb-conciliation": rpaConciliacao,
      "viewb-cancellation": rpaConciliacao,
      "elev-bitrix-omie": rpaConciliacao,
      "bamko-any-omie": rpaConciliacao,
      inadimplencia: rpaConciliacao
    };

    const config = Object.keys(configs).find((key) => {
      const routePrefix = location.pathname.split("/")?.[1];
      const findConfigWhenURLEqualsKey = routePrefix === key;
      return findConfigWhenURLEqualsKey;
    });

    return {
      data: configs[config]
    };
  }
  //
  //
  //
});

registerApplication({
  name: "@intraversa/portico-uploader",
  app: () => System.import("@intraversa/portico-uploader"),
  activeWhen: () => {
    return showWhenPrefix([
      "/oeste-",
      "/oeste-vindi-subscription",
      "/oeste-subscription",
      "/oeste-cancellation",
      "/oeste-vindi-report",
      "/oeste-vindi-cancelamento",
      "/oeste-vindi-reativacao",
      "/oeste-vindi-atualizacao",
      "/oeste-vindi-faturas",
      "/oeste-pagarme-reports",
      "/oeste-vindi-novas-assinaturas",
      "/oeste-link-pagamento",
      "/oeste-vindi-extract",
      "/oeste-carrinho-abandonado",
      "/oeste-lives",
      "/oeste-vindi-atualizacao-fatura",
      "/oeste-conciliacao",
      "/oeste-vindi-gcp",
      "/oeste-vindi-gcp",
      "/oeste-vindi-baixa-manual",
      "/cspesca-omie-conciliacao-tabela-preco",
      "/cielo-conciliacao-recebimentos"
    ]);
  },
  customProps: () => {
    const configs = {
      "oeste-cancellation": cancellationOeste,
      "oeste-subscription": renewOeste,
      "oeste-vindi-subscription": renewVindiOeste,
      "oeste-vindi-report": renewVindiReport,
      "oeste-vindi-cancelamento": oesteVindiCancelamento,
      "oeste-vindi-reativacao": oesteVindiReativacao,
      "oeste-vindi-atualizacao": oesteVindiAtualizacao,
      "oeste-vindi-faturas": oesteVindiFaturas,
      "oeste-pagarme-reports": oestePagarmeReport,
      "oeste-vindi-novas-assinaturas": oesteVindiNovaAssinatura,
      "oeste-link-pagamento": oesteLinkPagamento,
      "oeste-vindi-extract": oesteVindiExtract,
      "oeste-carrinho-abandonado": oesteCarrinhoAbandonado,
      "oeste-lives": oesteAcompanhamentoLives,
      "oeste-vindi-atualizacao-fatura": oesteVindiAtualizacaoFatura,
      "oeste-conciliacao": oesteConciliacao,
      "oeste-vindi-gcp": oesteVindiGcp,
      "oeste-vindi-atualizacao-cliente": oesteVindiAtualizacaoCliente,
      "oeste-vindi-baixa-manual": oesteVindiBaixaManual,
      "cspesca-omie-conciliacao-tabela-preco": cspescaConciliacaoTabelasPreco,
      "cielo-conciliacao-recebimentos": cieloConciliacaoRecebimentos
    };
    const config = Object.keys(configs).find((key) => {
      const routePrefix = location.pathname.split("/")?.[1];
      const findConfigWhenURLEqualsKey = routePrefix === key;
      return findConfigWhenURLEqualsKey;
    });

    return {
      data: configs[config]
    };
  }
});

registerApplication({
  name: "@intraversa/portico-chat",
  app: () => System.import("@intraversa/portico-chat"),
  activeWhen: () => {
    return showWhenPrefix(["/elev-chat-wnf", "/elev-whatsapp"]);
  },
  customProps: () => {
    const configs = {
      "elev-chat-wnf": wnfChat,
      "elev-whatsapp": elevWhatsApp
    };
    const config = Object.keys(configs).find((key) => {
      const routePrefix = location.pathname.split("/")?.[1];
      const findConfigWhenURLEqualsKey = routePrefix === key;
      return findConfigWhenURLEqualsKey;
    });

    return {
      data: configs[config]
    };
  }
});

start();
